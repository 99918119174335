<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="searchBill">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div v-if="this.bill_payment_statistics!==undefined">
        <a-row>
          <a-col :span="11">
            <a-descriptions bordered title="" :size="size">
              <a-descriptions-item label="账单类型">
                租金账单
              </a-descriptions-item>
              <a-descriptions-item label="期望收款" :span="2">
                {{ (this.bill_payment_statistics.rent.expected_collection / 100).toFixed(2) | numberFormat }}
              </a-descriptions-item>
              <a-descriptions-item label="提前缴费">
                {{ (this.bill_payment_statistics.rent.advance_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.rent.advance.toFixed(2) }}%)
              </a-descriptions-item>
              <a-descriptions-item label="滞后缴费">
                {{ (this.bill_payment_statistics.rent.delay_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.rent.delay.toFixed(2) }}%)
              </a-descriptions-item>
              <a-descriptions-item label="正常缴费">
                {{ (this.bill_payment_statistics.rent.normal_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.rent.normal.toFixed(2) }}%)
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :span="1">
          </a-col>
          <a-col :span="11">
            <a-descriptions bordered title="" :size="size">
              <a-descriptions-item label="账单类型">
                物业账单
              </a-descriptions-item>
              <a-descriptions-item label="期望收款" :span="2">
                {{ (this.bill_payment_statistics.property.expected_collection / 100).toFixed(2) | numberFormat }}
              </a-descriptions-item>
              <a-descriptions-item label="提前缴费">
                {{ (this.bill_payment_statistics.property.advance_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.property.advance.toFixed(2) }}%)
              </a-descriptions-item>
              <a-descriptions-item label="滞后缴费">
                {{ (this.bill_payment_statistics.property.delay_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.property.delay.toFixed(2) }}%)
              </a-descriptions-item>
              <a-descriptions-item label="正常缴费">
                {{ (this.bill_payment_statistics.property.normal_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.property.normal.toFixed(2) }}%)
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="11">
            <a-descriptions bordered title="" :size="size">
              <a-descriptions-item label="账单类型">
                水费账单
              </a-descriptions-item>
              <a-descriptions-item label="已缴费">
                {{ (this.bill_payment_statistics.water.paid_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.water.paid.toFixed(2) }}%)
              </a-descriptions-item>
              <a-descriptions-item label="未缴费">
                {{ (this.bill_payment_statistics.water.unpaid_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.water.unpaid.toFixed(2) }}%)
              </a-descriptions-item>
              <!--              <a-descriptions-item label="公司">-->
              <!--                <a @click="setCorporationInfo(bill_payment_statistics.water.corporation_info)">{{ this.bill_payment_statistics.water.corporation_info.length }}个</a>-->
              <!--              </a-descriptions-item>-->
            </a-descriptions>
          </a-col>
          <a-col :span="1">
          </a-col>
          <a-col :span="11">
            <a-descriptions bordered title="" :size="size">
              <a-descriptions-item label="账单类型">
                电费账单
              </a-descriptions-item>
              <a-descriptions-item label="已缴费">
                {{ (this.bill_payment_statistics.electricity.paid_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.electricity.paid.toFixed(2) }}%)
              </a-descriptions-item>
              <a-descriptions-item label="未缴费">
                {{ (this.bill_payment_statistics.electricity.unpaid_amount / 100).toFixed(2) | numberFormat }}
                <br/>
                ({{ this.bill_payment_statistics.electricity.unpaid.toFixed(2) }}%)
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="11">
            <div v-if="this.bill_payment_statistics.rent.advance_corporation.length>0">
              <a-divider orientation="left">
                租金-预缴费公司
              </a-divider>
              <a-table
                ref="table"
                size="small"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="this.bill_payment_statistics.rent.advance_corporation">
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
                  {{ (text / 100).toFixed(2) | numberFormat }}
                  <br/>
                </div>
              </a-table>
            </div>
            <div v-if="this.bill_payment_statistics.rent.delay_corporation.length>0">
              <a-divider orientation="left">
                租金-滞后缴费公司
              </a-divider>
              <a-table
                ref="table"
                size="small"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="this.bill_payment_statistics.rent.delay_corporation">
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
                  {{ (text / 100).toFixed(2) | numberFormat }}
                </div>
              </a-table>
            </div>
            <div v-if="this.bill_payment_statistics.water.corporation_info.length>0">
              <a-divider orientation="left">
                水费-缴费公司
              </a-divider>
              <a-table
                ref="table"
                size="small"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="this.bill_payment_statistics.water.corporation_info">
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
                  {{ (text / 100).toFixed(2) | numberFormat }}
                </div>
              </a-table>
            </div>
          </a-col>
          <a-col :span="1">
          </a-col>
          <a-col :span="11">
            <div v-if="this.bill_payment_statistics.property.advance_corporation.length>0">
              <a-divider orientation="left">
                物业-预缴费公司
              </a-divider>
              <a-table
                ref="table"
                size="small"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="this.bill_payment_statistics.property.advance_corporation">
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
                  {{ (text / 100).toFixed(2) | numberFormat }}
                </div>
              </a-table>
            </div>
            <div v-if="this.bill_payment_statistics.property.delay_corporation.length>0">
              <a-divider orientation="left">
                物业-滞后缴费公司
              </a-divider>
              <a-table
                ref="table"
                size="small"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="this.bill_payment_statistics.property.delay_corporation">
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
                  {{ (text / 100).toFixed(2) | numberFormat }}
                </div>
              </a-table>
            </div>
            <div v-if="this.bill_payment_statistics.electricity.corporation_info.length>0">
              <a-divider orientation="left">
                电费-缴费公司
              </a-divider>
              <a-table
                ref="table"
                size="small"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="this.bill_payment_statistics.electricity.corporation_info">
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
                  {{ (text / 100).toFixed(2) | numberFormat }}
                </div>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-else>
        <a-empty :image="simpleImage" />
      </div>
    </div>
  </div>
</template>

<script>
import { STable } from '@/components'
import { bill_payment_statistics } from '@/api/rent_bill'
import AEmpty from 'ant-design-vue/es/empty'

const { Empty } = require('ant-design-vue')
export default {
  name: 'TableList',
  components: {
    STable,
    AEmpty
  },
  data () {
    return {
      size: 'small',
      drawer_title: '企业列表',
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      dataSource: [],
      loading: false,
      visible: false,
      // 高级搜索 展开/关闭
      // 查询参数
      queryParam: {},
      bill_payment_statistics: undefined,
      // 加载数据方法 必须为 Promise 对象
      selectedRowKeys: [],
      selectedRows: [],
      corporationInfo: [],
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          width: 50
        },
        {
          title: '企业名称',
          dataIndex: 'corporation_name',
          ellipsis: true
        },
        {
          title: '金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 120,
          ellipsis: true
        },
        {
          title: '时间',
          dataIndex: 'date',
          width: 100,
          align: 'center'
        }
      ],
      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    // this.queryParam.start_date = '1970-01-01'
    // this.queryParam.end_date = '1970-01-01'
  },
  methods: {
    showDrawer () {
      this.visible = true
    },
    onClose () {
      this.visible = false
    },
    setCorporationInfo (corporation_list, title = '公司列表') {
      this.corporationInfo = corporation_list
      this.showDrawer()
      console.log(corporation_list)
      this.drawer_title = title
    },
    formatter (count) {
      if (count === undefined || count.value === undefined) {
        return 0.00
      }
      return count.value / 100
    },
    searchBill () {
     this.getData()
    },
    onChange (value) {
      console.log(value)
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings[0] === '') {
        this.queryParam.start_date = undefined
        this.queryParam.end_date = undefined
        return
      }
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    getData () {
      bill_payment_statistics(Object.assign(this.queryParam))
        .then(res => {
          console.log(res)
          if (res.code === 1000) {
            this.bill_payment_statistics = res.data
          } else {
            this.bill_payment_statistics = undefined
          }

          this.setCorporationInfo(this.bill_payment_statistics.rent.advance_corporation)
        })
    }
  }
}
</script>
